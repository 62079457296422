import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import GalleryPage from './components/GalleryPage';
import { Analytics } from "@vercel/analytics/react"

function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<LandingPage />} />
                    <Route path="/galerie" element={<GalleryPage />} />
                </Routes>
            </Router>
            <Analytics></Analytics>
        </>
    );
}

export default App;

