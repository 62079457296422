import React, { useState } from 'react';
import { Image, X } from 'lucide-react';

const GalleryItem = ({ src, alt, caption, description, onClick }) => (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105 cursor-pointer" onClick={onClick}>
        <img src={src} alt={alt} className="w-full h-48 object-cover" />
        <div className="p-4">
            <h3 className="font-semibold text-lg mb-2 text-gray-800">{caption}</h3>
            <p className="text-gray-600 text-sm">{description}</p>
        </div>
    </div>
);

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
                <button onClick={onClose} className="float-right text-gray-600 hover:text-gray-800">
                    <X size={24} />
                </button>
                {children}
            </div>
        </div>
    );
};

const GalleryPage = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const galleryItems = [
        {
            src: "/api/placeholder/400/300",
            alt: "Skupinová aktivita",
            caption: "Skupinové aktivity",
            description: "Naše skupinové aktivity pomáhají klientům v sociální interakci a budování vztahů v bezpečném prostředí. Tyto setkání podporují vzájemnou podporu a sdílení zkušeností mezi účastníky."
        },
        {
            src: "/api/placeholder/400/300",
            alt: "Individuální terapie",
            caption: "Individuální terapie",
            description: "Poskytujeme individuální terapie přizpůsobené specifickým potřebám každého klienta. Naši zkušení terapeuti pracují na zlepšení kognitivních funkcí, řeči a motorických dovedností."
        },
        {
            src: "/api/placeholder/400/300",
            alt: "Komunitní setkání",
            caption: "Komunitní setkání",
            description: "Pravidelná komunitní setkání posilují vztahy a podporu mezi klienty, jejich rodinami a pečovateli. Tyto akce vytváří prostor pro sdílení, učení a vzájemnou inspiraci."
        },
        {
            src: "/api/placeholder/400/300",
            alt: "Rekondiční pobyt",
            caption: "Rekondiční pobyty",
            description: "Organizujeme rekondiční pobyty v přírodě, které pomáhají v celkovém zotavení. Tyto pobyty kombinují terapeutické aktivity s relaxací a společenskými aktivitami v příjemném prostředí."
        },
        {
            src: "/api/placeholder/400/300",
            alt: "Vzdělávací workshop",
            caption: "Vzdělávací workshopy",
            description: "Pořádáme vzdělávací workshopy pro klienty i pečující osoby. Tyto semináře poskytují cenné informace o nejnovějších postupech v rehabilitaci, právních otázkách a dostupných zdrojích podpory."
        },
        {
            src: "/api/placeholder/400/300",
            alt: "Sportovní aktivita",
            caption: "Sportovní aktivity",
            description: "Nabízíme různé sportovní aktivity, které podporují fyzické i mentální zdraví našich klientů. Tyto aktivity jsou přizpůsobeny různým úrovním schopností a podporují aktivní životní styl."
        },
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white font-sans">
            <header className="bg-white shadow-sm">
                <div className="container mx-auto px-4 py-6 flex justify-between items-center">
                    <h1 className="text-4xl font-bold">
                        <span className="text-blue-600">SVÉ</span>
                        <span className="text-green-600">HLAVY</span>
                    </h1>
                    <nav>
                        <ul className="flex space-x-6">
                            <li><a href="/" className="text-gray-700 hover:text-blue-600 transition duration-300">Domů</a></li>
                            <li><a href="/#o-nas" className="text-gray-700 hover:text-blue-600 transition duration-300">O nás</a></li>
                            <li><a href="/#sluzby" className="text-gray-700 hover:text-blue-600 transition duration-300">Služby</a></li>
                            <li><a href="/#aktivity" className="text-gray-700 hover:text-blue-600 transition duration-300">Aktivity</a></li>
                            <li><a href="/galerie" className="text-gray-700 hover:text-blue-600 transition duration-300">Galerie</a></li>
                            <li><a href="/#kontakt" className="text-gray-700 hover:text-blue-600 transition duration-300">Kontakt</a></li>
                        </ul>
                    </nav>
                </div>
            </header>

            <main className="container mx-auto px-4 py-12">
                <h2 className="text-3xl font-semibold mb-8 flex items-center text-gray-800">
                    <Image className="mr-3 text-blue-600" size={36} />
                    Galerie našich aktivit
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {galleryItems.map((item, index) => (
                        <GalleryItem
                            key={index}
                            {...item}
                            onClick={() => setSelectedImage(item)}
                        />
                    ))}
                </div>
            </main>

            <Modal isOpen={!!selectedImage} onClose={() => setSelectedImage(null)}>
                {selectedImage && (
                    <div>
                        <img src={selectedImage.src} alt={selectedImage.alt} className="w-full h-64 object-cover mb-4 rounded" />
                        <h3 className="text-2xl font-semibold mb-2">{selectedImage.caption}</h3>
                        <p className="text-gray-700">{selectedImage.description}</p>
                    </div>
                )}
            </Modal>

            <footer className="bg-gray-800 text-white py-8">
                <div className="container mx-auto px-4 text-center">
                    <h1 className="text-4xl font-bold">
                        <span className="text-blue-400">SVÉ</span>
                        <span className="text-green-400">HLAVY</span>
                    </h1>
                    <p className="mt-4">&copy; 2024 SVÉHLAVY, z.s. Všechna práva vyhrazena.</p>
                </div>
            </footer>
        </div>
    );
};

export default GalleryPage;