import React from 'react';
import { Brain, Users, Calendar, HeartHandshake, MessageCircle, ChevronRight } from 'lucide-react';

const Logo = () => (
    <h1 className="text-4xl font-bold">
        <span className="text-blue-600">SVÉ</span>
        <span className="text-green-600">HLAVY</span>
    </h1>
);

const Section = ({ title, children, icon: Icon, bgColor = "bg-white", id }) => (
    <section className={`py-16 ${bgColor}`} id={id}>
        <div className="container mx-auto px-4">
            <h2 className="text-3xl font-semibold mb-8 flex items-center text-gray-800">
                <Icon className="mr-3 text-blue-600" size={36} />
                {title}
            </h2>
            {children}
        </div>
    </section>
);

const Card = ({ title, content, icon: Icon }) => (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-4 transition duration-300 hover:shadow-xl">
        <Icon className="text-blue-600 mb-4" size={32} />
        <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
        <p className="text-gray-600">{content}</p>
    </div>
);

const Button = ({ children, href }) => (
    <a
        href={href}
        className="inline-flex items-center px-6 py-3 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-700 transition duration-300"
    >
        {children}
        <ChevronRight className="ml-2" size={20} />
    </a>
);

const LandingPage = () => (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white font-sans">
        <header className="bg-white shadow-sm">
            <div className="container mx-auto px-4 py-6 flex justify-between items-center">
                <Logo />
                <nav>
                    <ul className="flex space-x-6">
                        <li><a href="#o-nas" className="text-gray-700 hover:text-blue-600 transition duration-300">O nás</a></li>
                        <li><a href="#sluzby" className="text-gray-700 hover:text-blue-600 transition duration-300">Služby</a></li>
                        <li><a href="#aktivity" className="text-gray-700 hover:text-blue-600 transition duration-300">Aktivity</a></li>
                        <li><a href="/galerie" className="text-gray-700 hover:text-blue-600 transition duration-300">Galerie</a></li>
                        <li><a href="#kontakt" className="text-gray-700 hover:text-blue-600 transition duration-300">Kontakt</a></li>
                    </ul>
                </nav>
            </div>
        </header>

        <main>
            <section className="bg-blue-100 text-gray-800 py-20">
                <div className="container mx-auto px-4 text-center">
                    <h1 className="text-5xl font-bold mb-6">Podpora zotavení po poranění mozku</h1>
                    <p className="text-xl mb-8 max-w-2xl mx-auto">
                        Pomáháme lidem s získaným poškozením mozku zotavit se a znovu získat nezávislost prostřednictvím inovativní podpory a zapojení komunity.
                    </p>
                    <Button href="#o-nas">Zjistit více</Button>
                </div>
            </section>

            <Section title="O nás" icon={Brain} id="o-nas" bgColor="bg-white">
                <div className="flex flex-col md:flex-row items-center">
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <p className="text-gray-700 mb-4">
                            SVÉHLAVY, z.s. je skupina mladých motivovaných lidí, které spojuje zájem o problematiku získaného poškození mozku a následnou rehabilitaci. Naším posláním je pomáhat lidem zotavujícím se po poškození mozku dosáhnout uzdravení a nezávislosti.
                        </p>
                        <p className="text-gray-700">
                            Naše motto: "Jsme srdcaři, ale máme své hlavy!" - "Pomáháme lidem po poškození mozku k zotavení a nezávislosti." - "Naším posláním je vytvářet nová spojení."
                        </p>
                    </div>
                    <div className="md:w-1/2">
                        <img src="/api/placeholder/500/300" alt="Tým při práci" className="rounded-lg shadow-md" />
                    </div>
                </div>
            </Section>

            <Section title="Naše služby" icon={HeartHandshake} id="sluzby" bgColor="bg-blue-50">
                <div className="grid md:grid-cols-2 gap-6">
                    <Card
                        icon={Brain}
                        title="AI virtuální poradce"
                        content="Vytvořili jsme Pinocchia, AI poradce, který poskytuje rychlé odpovědi na otázky o získaném poškození mozku, péči o blízké a sociálně-právních záležitostech."
                    />
                    <Card
                        icon={Users}
                        title="Sociální služby"
                        content="Nabízíme včasné a efektivní poradenství, sociální rehabilitaci, skupinové i individuální terapie, rekondiční pobyty a další komunitní aktivity."
                    />
                    <Card
                        icon={HeartHandshake}
                        title="Peer podpora"
                        content="Aplikujeme efektivní podpůrné metody, jako je peer koncept a svépomocná setkání, nabízíme různé možnosti aktivního trávení volného času a podporu komunity."
                    />
                    <Card
                        icon={Users}
                        title="Veřejné akce"
                        content="Organizujeme akce ve veřejném prostoru, abychom propojili světy zdravých a nemocných, podporovali empatii a vzájemnou podporu."
                    />
                </div>
            </Section>

            <Section title="Aktivity" icon={Calendar} id="aktivity" bgColor="bg-white">
                <div className="bg-blue-50 rounded-lg shadow-lg p-8">
                    <p className="text-gray-700 mb-4">
                        Organizujeme různé rekondiční pobyty, výlety, sportovní akce, kulturní a vzdělávací setkání. Naším cílem je usnadnit osobní interakce, aktivní trávení volného času a obnovení ztracených kompetencí pro dosažení co nejvyšší míry soběstačnosti a nezávislosti našich klientů.
                    </p>
                    <p className="text-gray-700 mb-6">
                        Prostřednictvím našeho webu www.potkamese.cz poskytujeme virtuální prostor pro lidi zotavující se po poškození mozku, kde se mohou setkat ve virtuálních skupinách, poznat se a najít inspiraci a motivaci.
                    </p>
                    <Button href="https://www.potkamese.cz">Navštívit Potkamese.cz</Button>
                </div>
            </Section>

            <Section title="Kontaktujte nás" icon={MessageCircle} id="kontakt" bgColor="bg-blue-50">
                <div className="bg-white rounded-lg shadow-lg p-8">
                    <p className="text-gray-700 mb-4">
                        Pokud vy nebo někdo, koho znáte, byl postižen poškozením mozku, nebo máte zájem podpořit naši věc, neváhejte nás kontaktovat.
                    </p>
                    <div className="text-gray-700">
                        <p className="mb-2"><strong>E-mail:</strong> info@svehlavy.cz</p>
                        <p className="mb-2"><strong>Telefon:</strong> +420 123 456 789</p>
                        <p><strong>Adresa:</strong> Praha, Česká republika</p>
                    </div>
                </div>
            </Section>
        </main>

        <footer className="bg-gray-800 text-white py-8">
            <div className="container mx-auto px-4 text-center">
                <Logo />
                <p className="mt-4">&copy; 2024 SVÉHLAVY, z.s. Všechna práva vyhrazena.</p>
            </div>
        </footer>
    </div>
);

export default LandingPage;